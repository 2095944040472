<template>
    <el-dialog :title="op == 'add' ? '新增媒介' : '修改媒介'" :close-on-click-modal="false" :visible="dialogShow" @close="closeDialog">
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item prop="value" label="媒介名称" label-width="200px">
                <el-input style="width: 70%" v-model="form.value" autocomplete="off" clearable placeholder="请输入媒介名称"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { systempropertiesAdd, systempropertiesUpdate, systempropertiesQuery, uniqueValidatorMediaName } from "@/api/statisticalReport";

export default {
    props: ["visible", "op", "addType"],
    data() {
        const validateValue = (rule, value, callback) => {
            if ("" == value || !value) {
                callback(new Error("媒介名称不能为空"));
            } else {
                if (this.op != "add" && value == this.mediaName) {
                    callback();
                    return;
                }
                let params = {
                    type: 1,
                    value,
                };
                uniqueValidatorMediaName(params)
                    .then((res) => {
                        let flag = res.data;
                        if (!flag) {
                            callback();
                        } else {
                            callback(new Error("该媒介名称已存在，请重新输入媒介名称"));
                        }
                    })
                    .catch((err) => {
                        callback(new Error("媒介名称唯一校验失败，请稍后重试"));
                    });
            }
        };
        return {
            moment,
            dialogShow: false,
            mediaName: "",
            form: {
                value: "",
            },
            rules: {
                value: [
                    {
                        required: true,
                        trigger: "change",
                        validator: validateValue,
                    },
                ],
            },
        };
    },
    watch: {
        visible(data) {
            this.dialogShow = data;
            const _this = this;
            if (data && this.op != "add") {
                //查询详情
                _this.getInfo();
            }
        },
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    methods: {
        getInfo() {
            const _this = this;
            let params = {
                flowId: this.op,
            };
            const loading = _this.$loading({
                lock: true,
                text: "加载中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            systempropertiesQuery(params)
                .then((res) => {
                    loading.close();
                    this.$refs.form.resetFields();
                    _this.form = {
                        value: res.data.value,
                    };
                    _this.mediaName = JSON.parse(JSON.stringify(res.data.value));
                })
                .catch(() => {
                    loading.close();
                });
        },
        submit() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let params = {
                        value: _this.form.value,
                        type: 1,
                        userCode: _this.userInfo.userCode,
                    };
                    if (_this.op == "add") {
                        // 新增
                        const loading = _this.$loading({
                            lock: true,
                            text: "提交中,请稍后...",
                            spinner: "el-icon-loading",
                            background: "rgba(0, 0, 0, 0.7)",
                            customClass: "fullScreenLoading",
                        });
                        systempropertiesAdd(params)
                            .then(() => {
                                loading.close();
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                _this.closeDialog(true);
                            })
                            .catch((err) => {
                                loading.close();
                            });
                    } else {
                        // 更新
                        params.flowId = _this.op;
                        const loading = _this.$loading({
                            lock: true,
                            text: "提交中,请稍后...",
                            spinner: "el-icon-loading",
                            background: "rgba(0, 0, 0, 0.7)",
                            customClass: "fullScreenLoading",
                        });
                        systempropertiesUpdate(params)
                            .then(() => {
                                loading.close();
                                _this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                _this.closeDialog(true);
                            })
                            .catch(() => {
                                loading.close();
                            });
                    }
                }
            });
        },
        closeDialog(flag) {
            this.$emit("onDialogClose", flag);
            this.$refs.form.resetFields();
            this.mediaName = "";
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.sendSmsText = "发送验证码";
        },
    },
};
</script>
